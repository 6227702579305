import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { from, fromEvent } from 'rxjs';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  menuItems: Array<MenuItem> = [];
  buttonNotifyMe!: HTMLElement;
  buttonSendEmail!: HTMLElement;
  emailContainer!: HTMLElement;
  emailInput!: HTMLElement;
  animationLoading: boolean = false;
  sendEmailForm: FormGroup;
  notifyMeClicked: boolean = false;
  thankYouLoading: boolean = false;
  isLoading: boolean = false;
  constructor(private http: HttpClient, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.menuItems = [
      {
        label: '<h3>Home</h3>',
        escape: false,
        routerLink: '/',
        routerLinkActiveOptions: {
          exact: true
        }
      },
      {
        label: '<h3>About us</h3>',
        escape: false,
        routerLink: '/about-us',
        routerLinkActiveOptions: {
          exact: true
        }
      },
      {
        label: '<h3>FAQs</h3>',
        escape: false,
        routerLink: '/faqs',
        routerLinkActiveOptions: {
          exact: true
        }
      },
      {
        label: '<h3>Contact us</h3>',
        escape: false,
        routerLink: '/contact-us',
        routerLinkActiveOptions: {
          exact: true
        }
      }
    ];

    this.initSendEmailForm();
  }

  initSendEmailForm() {
    this.sendEmailForm = new FormGroup({
      emailAddress1: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });
    this.animationLoading = false;
  }

  emailInputEventMethod(ev): void {
    if (ev.relatedTarget && ev.relatedTarget.id && ev.relatedTarget.id === 'buttonSendEmail1') {
      ev.preventDefault();
      return;
    }
    this.hideSendEmailAnimation(() => {
      this.sendEmailForm.controls['emailAddress1'].reset();
    });
  }

  buttonSendEmailEventMethod(): void {
    if (!this.animationLoading) {
      this.isLoading = true;
      var email = this.sendEmailForm.controls['emailAddress1'].value;
      this.http.post('api/ContactUs/notifyme?email=' + email, null).subscribe(
        (response) => {
          if (response) {
            this.dialogService.showMessageDialog('Submit completed!');
            this.initSendEmailForm();
          } else {
            this.dialogService.showErrorDialog('There was an error!');
          }
        },
        (err) => {
          this.dialogService.showErrorDialog('There was an error!');
        }
      );

      setTimeout(() => {
        this.isLoading = false;
        this.notifyMeClicked = false;
        this.sendEmailForm.controls['emailAddress1'].reset();

        this.hideSendEmailAnimation(() => {
          this.thankYouLoading = true;
          setTimeout(() => {
            this.thankYouLoading = false;
            this.animationLoading = false;
          }, 1000);
        });

      }, 3000);
    }
  }

  buttonNotifyMeEventMethod(): void {
    this.buttonNotifyMe = document.getElementById('buttonNotifyMe1');
    this.emailContainer = document.getElementById('emailContainer1');
    this.buttonSendEmail = document.getElementById('buttonSendEmail1');
    this.emailInput = document.getElementById('emailInput1');
    if (!this.animationLoading) {
      this.animationLoading = true;
      this.buttonNotifyMe.style.display = 'none';
      this.buttonSendEmail.style.visibility = 'hidden';
      this.emailContainer.classList.remove('hidenotifymeinput-animation');
      this.emailContainer.classList.add('shownotifymeinput-animation');
      this.buttonSendEmail.classList.remove('hidesendemailbutton-animation');

      setTimeout(() => {
        this.buttonSendEmail.classList.add('showsendemailbutton-animation');
        this.buttonSendEmail.style.visibility = 'visible';
        this.emailInput.focus();
        this.animationLoading = false;

      }, 1000);
    }
  }
  hideSendEmailAnimation(callback: Function | undefined = undefined) {
    if (!this.animationLoading) {
      this.animationLoading = true;
      this.buttonSendEmail.classList.remove('showsendemailbutton-animation');
      this.buttonSendEmail.classList.add('hidesendemailbutton-animation');

      this.emailContainer.classList.remove('shownotifymeinput-animation');
      setTimeout(() => {
        this.buttonSendEmail.style.visibility = 'hidden';
      }, 200);

      this.emailContainer.classList.add('hidenotifymeinput-animation');
      setTimeout(() => {
        this.buttonNotifyMe.style.display = 'inline-flex';
        if (callback) {
          callback();
        }
        this.animationLoading = false;
      }, 500);
    }

  }

  notifyMe(): void {
    //location.href = `${environment.webBaseUrl}/onboarding/signup/creator`;
  }

}
