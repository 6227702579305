import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { ProblemDetailsHttp } from '../models/problem.details.http';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private _confirmationService: ConfirmationService,
    private _router: Router
  ) { }

  showErrorDialog(error: string | HttpErrorResponse, header?: string, accept?: Function) {
    if (typeof error === "string") {
      this._confirmationService.confirm({
        header: header ?? 'Service Not Available',
        message: error,
        key: 'errorDialog',
        accept: accept
      });
    } else {
      let title = 'Service Not Available!';
      let detail = 'Please try again later.';

      if (error && error.status != 0) {
        const problem = error.error as ProblemDetailsHttp;
        if (problem && problem.title) {
          title = problem.title;
          detail = problem.detail;
        }
        else {
          title = error.statusText;
          detail = error.message;
        }
      }
    }
  }

  showConfirmDialog(header?: string, message?: string, accept?: Function, reject?: Function) {
    this._confirmationService.confirm({
      key: 'confirmDialog',
      header: header,
      message: message,
      accept: accept,
      reject: reject,
    });
  }

  showMessageDialog(header?: string, message?: string, accept?: Function) {
    this._confirmationService.confirm({
      key: 'messageDialog',
      header: header,
      message: message,
      accept: accept,
    });
  }

}
