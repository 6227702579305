import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MessageModule } from "primeng/message";
import { TooltipModule } from "primeng/tooltip";
import { ButtonModule } from "primeng/button";
import { FooterComponent } from './components/footer/footer.component';
import {MenubarModule} from 'primeng/menubar';
import { SharedModule } from 'primeng/api';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    ButtonModule,
    MessageModule,
    MenubarModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    HeaderComponent,
    FooterComponent
  ]
})
export class ExtraModule { }
