<div class="homepage-block-root">

  <div class="section1">
    <div class="homepage-section1-title-container">
      <div class="homepage-section1-title">
        <h1>
          <span style="display: block;">Complete Platform</span>
          <span>In One App</span>
        </h1>
      </div>
      <div>
        <div>Is the only platform designed to guarantee</div>
        <div>independence to content creators</div>
      </div>
      <div>
        <h3><span class="section1-notification-days">{{launchDay}} days</span> until launch</h3>

        <div class="notify-me-container">
          <button id="buttonNotifyMe" pButton class="p-button-primary home-notify-me">
            <span class="home-notify-me-icon-blue" *ngIf="!notifyMeClicked && !thankYouLoading;"></span>
            <span *ngIf="!notifyMeClicked && !thankYouLoading;">Notify me</span>
            <div *ngIf="!notifyMeClicked && thankYouLoading;">
              <span>Thank you!</span>
            </div>
          </button>
          <form id="emailContainer" class="email-container" [formGroup]="sendEmailForm">
            <input id="emailInput" formControlName="emailAddress" type="email" pInputText class="email-input" [readOnly]="isLoading">
            <button id="buttonSendEmail" pButton [ngClass]="(isLoading ? 'email-send-button-loading':'email-send-button')"
            class="email-send-button" [loading]="isLoading"
            [disabled]="!sendEmailForm.controls['emailAddress'].valid">
            {{isLoading ? '' : 'Send'}}
          </button>
          </form>
        </div>

      </div>
    </div>
    <div class="section1-image-container">
      <img class="section1-image" src="../../../assets/Images/Home/Section1-background.png" />
    </div>
  </div>
  <div class="section2">
    <h1 class="homepage-desktop section2-header-title">Some benefits</h1>
    <h1 class="homepage-mobile section2-header-title-mobile">Some benefits</h1>
    <div class="slider homepage-desktop">
      <p-carousel [value]="sliderDesktopList" [autoplayInterval]="3000" [numVisible]="4" [showNavigators]="false"
                  [circular]="false" [showIndicators]="false">
        <ng-template let-item pTemplate="item">
          <div class="slider-placeholder">
            <img src="{{item.imageSrc}}" class="slider-image" [ngClass]="item.sliderImageCssClass" />
            <div class="sec2-image-title">
              {{item.contentTitle}}
            </div>
            <div class="sec2-image-description" [innerHTML]="item.contentDescription">
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>

    <div class="homepage-mobile slider">

      <p-carousel [value]="sliderDesktopList"
      [circular]="true" [showIndicators]="true" [numVisible]="1" [numScroll]="1">
        <ng-template let-item pTemplate="item">
          <div class="static-slider">
            <img src="{{item.imageSrc}}" *ngIf="item.imageSrc!=null" class="slider-image-mobile"/>
            <h1 *ngIf="item.contentTitle!=null" class="slider-image-title-mobile">
              {{item.contentTitle}}
            </h1>
            <p *ngIf="item.contentDescription!=null"  [innerHTML]="item.contentDescription" class="slider-description-mobile">              
            </p>             
          </div>
        </ng-template>
      </p-carousel>
  
    </div>
  </div>

  <div class="homepage-section3">
    <div class="homepage-section3-title-container">
      <div class="section3-title">
        <h1>
          <span style="display: block;">Get better care</span>
        </h1>
      </div>
      <div class="homepage-section3-description">
        <div class="homepage-section3-description-content">Receive weekly practical tips on how to communicate visually, right in your inbox</div>
        <div class="homepage-desktop section3-subscription">
          <form [formGroup]="subscribeForm">
            <div class="p-inputgroup">
              <input type="email" class="homepage-desktop section3-subscription-input form-input" formControlName="email" required pInputText placeholder="Email address">
              <button pButton (click)="subscribe()" [disabled]="!subscribeForm.controls['email'].valid" [loading]="isSubscibeLoading" class="homepage-desktop p-button-primary section3-subscription-button">
                Subscribe
              </button>
            </div>
            <label class="homepage-desktop error-label"
                   *ngIf="!subscribeForm.controls['email'].valid  && (subscribeForm.controls['email'].dirty || subscribeForm.controls['email'].touched)">
              Please enter a valid email.
            </label>
          </form>
        </div>
        <div class="homepage-mobile section3-subscription-mobile">
          <form [formGroup]="subscribeForm">
            <div class="p-inputgroup">
              <input type="email" class="homepage-mobile section3-subscription-input form-input" formControlName="email" required pInputText placeholder="Email address">
              <button pButton (click)="subscribe()" [disabled]="!subscribeForm.controls['email'].valid" [loading]="isSubscibeLoading" class="homepage-mobile p-button-primary section3-subscription-button">
                Subscribe
              </button>
            </div>
            <label class="homepage-mobile error-label"
                   *ngIf="!subscribeForm.controls['email'].valid  && (subscribeForm.controls['email'].dirty || subscribeForm.controls['email'].touched)">
              Please enter a valid email.
            </label>
          </form>
        </div>
      </div>      
    </div>
    <div class="homepage-desktop">
      <img class="section3-device" src="../../../assets/Images/Home/Section3-Device.png" />
    </div>
  </div>

</div>
