<div class="au-block-root">
  <div class="homepage-desktop section0">
    <h1 class="section0-header-title1">Only platform designed to guarantee independence to content creators.</h1>
    <div class="section0-subscription">
      <form [formGroup]="subscribeForm">
        <div class="p-inputgroup">
          <input type="email" class="section0-subscription-input  form-input" formControlName="email" required  pInputText placeholder="Email address">
          <button pButton (click)="subscribe()" [disabled]="!subscribeForm.controls['email'].valid" [loading]="isSubscibeLoading" class="p-button-primary section0-subscription-button">
            Subscribe
          </button>
        </div>
        <label class="error-label"
               *ngIf="!subscribeForm.controls['email'].valid  && (subscribeForm.controls['email'].dirty || subscribeForm.controls['email'].touched)">
          Please enter a valid email.
        </label>
      </form>
    </div>
  </div>
  <div class="homepage-mobile section0">
    <h1 class="section0-header-title1-mobile">Only platform designed to guarantee independence to content creators.</h1>
    <div class="section0-subscription-mobile">
      <form [formGroup]="subscribeForm">
        <div class="p-inputgroup">
          <input type="email" class="section0-subscription-input  form-input" formControlName="email" required  pInputText placeholder="Email address">
          <button pButton (click)="subscribe()" [disabled]="!subscribeForm.controls['email'].valid" [loading]="isSubscibeLoading" class="p-button-primary section0-subscription-button">
            Subscribe
          </button>
        </div>
        <label class="error-label"
               *ngIf="!subscribeForm.controls['email'].valid  && (subscribeForm.controls['email'].dirty || subscribeForm.controls['email'].touched)">
          Please enter a valid email.
        </label>
      </form>
    </div>
  </div>
  <div class="au-section1">
    <img class="au-section1-background" src="../../../assets/Images/About/aboutus-section1.png" />
  </div>
  <div class="au-section2">
    <div class="homepage-desktop au-section2-title-container">
      <div class="au-section2-title">
        <h1>
          <span style="display: block;">What's Payperclip?</span>
        </h1>
      </div>
      <div class="au-section2-description">
        <div>Community platform that makes it easy for creators to earn money. The way to get paid for creating the things you're already creating (images, videos, songs, whatevs). Fans pay per clips you release.</div><br>
        <div>With features like live-streaming with chat, community feeds and posts, and content libraries for video and audio, and all-in-one solution to interact with the content you love.</div>
      </div>
    </div>
    <div class="homepage-mobile au-section2-title-container-mobile">
      <div class="au-section2-title">
        <h1>
          <span style="display: block;">What's Payperclip?</span>
        </h1>
      </div>
      <div class="au-section2-description">
        <div>Community platform that makes it easy for creators to earn money. The way to get paid for creating the things you're already creating (images, videos, songs, whatevs). Fans pay per clips you release.</div><br>
        <div>With features like live-streaming with chat, community feeds and posts, and content libraries for video and audio, and all-in-one solution to interact with the content you love.</div>
      </div>
    </div>
    <div>
      <img class="homepage-desktop au-section2-device" src="../../../assets/Images/About/image-1.svg" />
    </div>
  </div>

  <div class="homepage-desktop au-section3">
    <h1 class="au-section3-header-title">While directly supporting the independence of the communities and creators you care about.</h1>
  </div>
  <div class="homepage-mobile au-section3">
    <h1 class="au-section3-header-title-mobile">While directly supporting the independence of the communities and creators you care about.</h1>
  </div>
  <div class="au-section4">
    <h1 class="homepage-desktop au-section4-header-title">All Features</h1>
    <h1 class="homepage-mobile au-section4-header-title-mobile">All Features</h1>
    <div class="au-sec4-search-results-block">
      <p-dataView [value]="searchResult" [paginator]="false" [rows]="2" layout="grid"
                  [loading]="isLoading"
                  [totalRecords]="6">
        <ng-template let-item pTemplate="gridItem">
          <div class="col-12 lg:col-4 md:col-6 sm:col-6">
            <div class="search-item-result">
              <div class="search-item-result-header">
                <p-avatar [image]="item.imgSrc" styleClass="mr-2" size="large"></p-avatar>
              </div>
              <div class="au-sec4-search-item-result-title">
                {{item.title}}
              </div>
              <div class="au-sec4-search-item-result-description">
                {{item.description}}
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>

  <div class="homepage-desktop au-section5">
    <h1 class="au-section5-header-title">Explore how Payperclip works</h1>
    <div class="au-section5-slider slider">
      <p-carousel [value]="sliderDesktopList" [autoplayInterval]="4000" [showNavigators]="true"
                  [circular]="true" [showIndicators]="false">
        <ng-template let-item pTemplate="item">
          <div class="au-section5-slider-placeholder">
            <img src="{{item.imageSrc}}" class="slider-image" [ngClass]="item.sliderImageCssClass" />
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>

  <div class="homepage-mobile au-section5">
    <h1 class="au-section5-header-title-mobile">Explore how Payperclip works</h1>
    <div class="au-section5-slider slider">
      <p-carousel [value]="sliderDesktopList" [autoplayInterval]="4000" [showNavigators]="true"
                  [circular]="true" [showIndicators]="false">
        <ng-template let-item pTemplate="item">
          <div class="au-section5-slider-placeholder">
            <img src="{{item.imageSrc}}" class="slider-image" [ngClass]="item.sliderImageCssClass" />
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>

  <div class="homepage-desktop au-section6">
    <h1 class="au-section6-header-title">My wallet</h1>
    <div class="au-section6-description">Allows the user to view their balance and how many currencies they have, Users can select a currency and see an in depth detail of it or withdraw to their personal bank account.</div>
  </div>
  <div class="homepage-mobile au-section6">
    <h1 class="au-section6-header-title">My wallet</h1>
    <div class="au-section6-description-mobile">Allows the user to view their balance and how many currencies they have, Users can select a currency and see an in depth detail of it or withdraw to their personal bank account.</div>
  </div>

  <div class="homepage-desktop au-section7">
    <h1 class="au-section7-header-title">Get better care</h1>
    <div class="au-section7-description">Receive weekly practical tips on how to communicate visually, right in your inbox</div>
    <div class="au-section7-subscription">
      <div class="p-inputgroup">
        <input type="text" class="au-section7-subscription-input" pInputText placeholder="Email address">
        <button pButton (click)="subscribe()" class="p-button-primary au-section7-subscription-button">
          Subscribe
        </button>
      </div>
    </div>
  </div>
  <div class="homepage-mobile au-section7">
    <h1 class="au-section7-header-title">Get better care</h1>
    <div class="au-section7-description-mobile">Receive weekly practical tips on how to communicate visually, right in your inbox</div>
    <div class="au-section7-subscription-mobile">
      <div class="p-inputgroup">
        <input type="text" class="au-section7-subscription-input" pInputText placeholder="Email address">
        <button pButton (click)="subscribe()" class="p-button-primary au-section7-subscription-button">
          Subscribe
        </button>
      </div>
    </div>
  </div>
</div>
