<div class="cu-block-root">
   <div class="section0">
      <h1 class="section0-header-title1">Contact us</h1>

   </div>
   <div class="cu-section1">
      <form [formGroup]="contactUsForm" class="cu-form">
        <div class="card">
          <h1 class="cu-form-label">Name</h1>
          <div class="ui-fluid">
            <div class="field p-inputgroup">
              <input class="form-input" pInputText id="Name" formControlName="name"
                     placeholder="Enter your name" />
              <label class="error-label"
                     *ngIf="!contactUsForm.controls['name'].valid  && (contactUsForm.controls['name'].dirty || contactUsForm.controls['name'].touched)">
                Please enter a valid name.
              </label>
            </div>
          </div>
          <div class="input-field ui-fluid">
            <h1 class="cu-form-label required">Email</h1>
            <div class="field p-inputgroup">
              <input required class="form-input" type="email" pInputText formControlName="email"
                     placeholder="Enter email address">
              <label class="error-label"
                     *ngIf="!contactUsForm.controls['email'].valid  && (contactUsForm.controls['email'].dirty || contactUsForm.controls['email'].touched)">
                Please enter a valid email.
              </label>
            </div>
          </div>
          <div class="input-field ui-fluid">
            <h1 class="cu-form-label">Subject</h1>
            <div class="field p-inputgroup">
              <input type="text" class="form-input" pInputText placeholder="Enter your subject"
                     formControlName="subject">
              <label class="error-label"
                     *ngIf="!contactUsForm.controls['subject'].valid  && (contactUsForm.controls['subject'].dirty || contactUsForm.controls['subject'].touched)">
                Please enter a valid Subject.
              </label>
            </div>
          </div>
          <div class="input-field ui-fluid">
            <h1 class="cu-form-label required">Your message</h1>
            <div class="field p-inputgroup">
              <textarea required type="text" class="form-input" pInputText rows="5" cols="70"
                        placeholder="Enter your message" id="textArea"
                        formControlName="messageTxt"></textarea>
              <label class="error-label"
                     *ngIf="!contactUsForm.controls['messageTxt'].valid  && (contactUsForm.controls['messageTxt'].dirty || contactUsForm.controls['messageTxt'].touched)">
                Please enter a valid message.
              </label>
            </div>
          </div>
          <div class="col-12">
            <re-captcha (resolved)="resolved($event)" class="center"
                        siteKey="{{appGoogleApiKey}}">
            </re-captcha>
          </div>
          <div class=" cu-form-submit center input-field ui-fluid">
            <button [loading]="isLoading" [disabled]="!isCaptchaVerified || !contactUsForm.controls['email'].valid || !contactUsForm.controls['messageTxt'].valid || !contactUsForm.controls['subject'].valid || !contactUsForm.controls['name'].valid" type="button" pButton class="p-button-primary  cu-submit-button submit p-button p-component"
                    (click)="sendMail()">
              Submit
            </button>
          </div>
        </div>
      </form>
   </div>

</div>
