import { ExtraModule } from './extra/extra.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Confirmation,ConfirmationService, MessageService } from 'primeng/api';
import {FileUploadModule} from 'primeng/fileupload';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { RippleModule } from 'primeng/ripple';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import { HomepageComponent } from './components/homepage/homepage.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FAQSComponent } from './components/faqs/faqs.component';
import {CarouselModule} from 'primeng/carousel';
import {InputTextModule} from 'primeng/inputtext';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DataViewModule} from 'primeng/dataview';
import {CardModule} from 'primeng/card';
import {AccordionModule} from 'primeng/accordion';
import {OrderListModule} from 'primeng/orderlist';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SubmitRequestComponent } from './components/submit-request/submit-request.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    AboutUsComponent,
    FAQSComponent,
    ContactUsComponent,
    SubmitRequestComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RippleModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    ConfirmDialogModule,
    DialogModule,
    ToastModule,
    AppRoutingModule,
    ExtraModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CarouselModule,
    InputTextModule,
    AvatarModule,
    AvatarGroupModule,
    ToggleButtonModule,
    DataViewModule,
    CardModule,
    AccordionModule,
    OrderListModule,
    BreadcrumbModule,
    CommonModule,
    FileUploadModule
  ],
  providers: [
    MessageService,
    ConfirmationService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
