<div class="header-desktop">
  <p-menubar [model]="menuItems">

    <ng-template pTemplate="start">
      <a routerLink="/" class="header-logo">
        <img src="assets/Images/logo_payperclip_only.svg" height="30" class="ml-5">
      </a>
    </ng-template>

    <ng-template pTemplate="end">
      <div class="notify-me-container">
        <button id="buttonNotifyMe1" (click)="buttonNotifyMeEventMethod()" pButton class="p-button-primary">
          <span class="home-notify-me-icon-white" *ngIf="!notifyMeClicked && !thankYouLoading;"></span>
          <span *ngIf="!notifyMeClicked && !thankYouLoading;">Notify me</span>
          <div *ngIf="!notifyMeClicked && thankYouLoading;">
            <span>Thank you!</span>
          </div>
        </button>
        <form id="emailContainer1" class="email-container" [formGroup]="sendEmailForm">
          <input id="emailInput1" (blur)="emailInputEventMethod($event)" formControlName="emailAddress1" type="email" pInputText class="email-input" [readOnly]="isLoading">
          <button id="buttonSendEmail1"  (click)="buttonSendEmailEventMethod()" pButton [ngClass]="(isLoading ? 'email-send-button-loading':'email-send-button')"
          class="email-send-button" [loading]="isLoading" 
          [disabled]="!sendEmailForm.controls['emailAddress1'].valid">
          {{isLoading ? '' : 'Send'}}
        </button>
        </form>
      </div>
    <!--  <div pButton  class="p-button-primary ">
        <span class="home-notify-me-icon-white"></span>
        <span class="header-notify-me" (click)="notifyMe()">Notify me</span>
      </div>-->
    </ng-template>

  </p-menubar>
</div>
<div class="header-mobile">
  <p-menubar [model]="menuItems">

    <a routerLink="/" class="header-logo">
      <img src="assets/Images/logo_payperclip_only.svg" height="30">
    </a>
    <a type="button" pButton class="p-button-primary" (click)="notifyMe()">Notify me</a>
  </p-menubar>
</div>
