import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input()
  footerHeader: string = "";

  @Input()
  footerMessage: string = "";

  @Input()
  footerButtonActionTitle: string = "";

  @Output()
  onFooterActionClicked = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
