
<div class="footer-desktop">
  <div class="footer-container">
    <div class="footer-address-container">
       <p>
         &copy; payperclipclub.com 2021
       </p>
    </div>
    <div class="footer-logo-container">
      <a routerLink="/">
        <img src="../../../assets/Images/Logo-White.svg" height="30"/>
      </a>
    </div>
    <div class="footer-icon-container">
      <div class="footer-icon inline-block" pTooltip="Instagram" tooltipPosition="top">
        <a href="https://instagram.com" target="_blank">
          <img src="../../../assets/Icons/Social-IG.svg" style="height: 30px;"/>
        </a>
      </div>
      <div class="footer-icon inline-block" pTooltip="Dribbble" tooltipPosition="top">
        <a href="https://dribbble.com/" target="_blank">
          <img src="../../../assets/Icons/Social-DP.svg" style="height: 30px;"/>
        </a>
      </div>
      <div class="footer-icon inline-block" pTooltip="Twitter" tooltipPosition="top">
        <a href="https://twitter.com" target="_blank">
          <img src="../../../assets/Icons/Social-TW.svg" style="height: 30px;"/>
        </a>
      </div>
      <div class="footer-icon inline-block" pTooltip="Youtube" tooltipPosition="top">
        <a href="https://youtube.com" target="_blank">
          <img src="../../../assets/Icons/Social-YT.svg" style="height: 30px;"/>
        </a>
      </div>

    </div>
  </div>
</div>

<div class="footer-mobile">
  <div class="footer-container">

    <div class="footer-logo-container">
      <a routerLink="/">
        <img src="../../../assets/Images/Logo-White.svg" height="30"/>
      </a>
    </div>
    <div class="footer-icon-container">
      <div class="footer-icon inline-block" pTooltip="Instagram" tooltipPosition="top">
        <a href="https://instagram.com" target="_blank">
          <img src="../../../assets/Icons/Social-IG.svg" style="height: 30px;"/>
        </a>
      </div>
      <div class="footer-icon inline-block" pTooltip="Dribbble" tooltipPosition="top">
        <a href="https://dribbble.com/" target="_blank">
          <img src="../../../assets/Icons/Social-DP.svg" style="height: 30px;"/>
        </a>
      </div>
      <div class="footer-icon inline-block" pTooltip="Twitter" tooltipPosition="top">
        <a href="https://twitter.com" target="_blank">
          <img src="../../../assets/Icons/Social-TW.svg" style="height: 30px;"/>
        </a>
      </div>
      <div class="footer-icon inline-block" pTooltip="Youtube" tooltipPosition="top">
        <a href="https://youtube.com" target="_blank">
          <img src="../../../assets/Icons/Social-YT.svg" style="height: 30px;"/>
        </a>
      </div>

    </div>

    <div class="footer-address-container">
      <p>
        &copy; payperclipclub.com 2021
      </p>
   </div>
  </div>
</div>
