import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../extra/services/dialog.service';
import { from, fromEvent } from 'rxjs';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements OnInit {
  sendEmailForm: FormGroup;
  subscribeForm: FormGroup;
  searchValue: string = '';
  secOneImgSrc: string = '../../../assets/Images/Home/Section1-background.jpg';
  totalRecords: number;
  isLoading: boolean = false;
  notifyMeClicked: boolean = false;
  thankYouLoading: boolean = false;
  isSubscibeLoading: boolean = false;
  launchDay: number = 100;
  buttonNotifyMe!: HTMLElement;
  buttonSendEmail!: HTMLElement;
  emailContainer!: HTMLElement;
  emailInput!:HTMLElement;
  animationLoading: boolean = false;

  sliderDesktopList = [
    {
      imageSrc: '../../../assets/Images/Home/1-My wallet.jpg',
      contentTitle: 'Make transfer quickly',
      contentDescription:
        'Get the payment instantly and <strong>directly into your local bank account</strong>. No waiting around for payouts',
    },
    {
      imageSrc: '../../../assets/Images/Home/2-Pick the prices.png',
      contentTitle: 'Optimize',
      contentDescription:
        '<strong>Create your own fan tiers</strong> with different benefits and price points to suit your audience',
    },
    {
      imageSrc: '../../../assets/Images/Home/3-Withdraw completed.jpg',
      contentTitle: 'Get Amazing deals',
      contentDescription:
        'It allows creators to set their clips to free or paid with pay <strong>only 0-5% fee</strong> of earnings',
    },
    {
      imageSrc: '../../../assets/Images/Home/4-Myclip.png',
      contentTitle: 'Unlimited potential',
      contentDescription:
        'Unlimited income potential. With starting a creator there is <strong>no cap</strong> as to how much income you can make',
    },
  ];

  constructor(private http: HttpClient, private dialogService: DialogService) {}

  ngOnInit(): void {
    var date2 = environment.appLaunchDate;
    var date1 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    this.initSendEmailForm();
    this.initSubscribeForm();

    // To calculate the no. of days between two dates
    this.launchDay = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
    window.scrollTo({
      top: 0,
    });
  }

  initSendEmailForm() {
    this.sendEmailForm = new FormGroup({
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });

    this.buttonNotifyMe = document.getElementById('buttonNotifyMe');
    this.emailContainer = document.getElementById('emailContainer');
    this.buttonSendEmail = document.getElementById('buttonSendEmail');
    this.emailInput = document.getElementById('emailInput');
    this.animationLoading = false;
    fromEvent(this.buttonNotifyMe, 'click').subscribe((ev) => {

      if(!this.animationLoading){
        this.animationLoading = true;
        this.buttonNotifyMe.style.display = 'none';
        this.buttonSendEmail.style.visibility = 'hidden';
        this.emailContainer.classList.remove('hidenotifymeinput-animation');
        this.emailContainer.classList.add('shownotifymeinput-animation');
        this.buttonSendEmail.classList.remove('hidesendemailbutton-animation');

        setTimeout(() => {
          this.buttonSendEmail.classList.add('showsendemailbutton-animation');
          this.buttonSendEmail.style.visibility = 'visible';
          this.emailInput.focus();
          this.animationLoading = false;

        }, 1000);
      }
    });

    fromEvent(this.buttonSendEmail, 'click').subscribe((ev) => {
      if(!this.animationLoading){
        this.isLoading = true;
        var email = this.sendEmailForm.controls['emailAddress'].value;
        this.http.post('api/ContactUs/notifyme?email=' + email, null).subscribe(
          (response) => {
            if (response) {
              this.dialogService.showMessageDialog('Submit completed!');
              this.initSendEmailForm();
            } else {
              this.dialogService.showErrorDialog('There was an error!');
            }
          },
          (err) => {
            this.dialogService.showErrorDialog('There was an error!');
          }
        );

        setTimeout(() => {
          this.isLoading = false;
          this.notifyMeClicked = false;
          this.sendEmailForm.controls['emailAddress'].reset();

          this.hideSendEmailAnimation(() => {
            this.thankYouLoading = true;
            setTimeout(() => {
              this.thankYouLoading = false;
              this.animationLoading = false;
            }, 1000);
          });

        }, 3000);
      }
    });

    fromEvent(this.emailInput,'blur').subscribe((ev:any) => {
      if(ev.relatedTarget && ev.relatedTarget.id && ev.relatedTarget.id === 'buttonSendEmail'){
        ev.preventDefault();
        return;
      }
      this.hideSendEmailAnimation(() => {
        this.sendEmailForm.controls['emailAddress'].reset();
      });
    });
  }

  hideSendEmailAnimation(callback: Function|undefined=undefined) {
    if(!this.animationLoading){
      this.animationLoading = true;
      this.buttonSendEmail.classList.remove('showsendemailbutton-animation');
      this.buttonSendEmail.classList.add('hidesendemailbutton-animation');

      this.emailContainer.classList.remove('shownotifymeinput-animation');
      setTimeout(() => {
        this.buttonSendEmail.style.visibility = 'hidden';
      }, 200);

      this.emailContainer.classList.add('hidenotifymeinput-animation');
      setTimeout(() => {
        this.buttonNotifyMe.style.display = 'inline-flex';
        if(callback){
          callback();
        }
        this.animationLoading = false;
      }, 500);
    }

  }

  initSubscribeForm(): void {
    this.subscribeForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ]),
    });
  }

  notifyMe(): void {
    this.notifyMeClicked = true;
    console.log('Notify me button clicked!');
  }

  subscribe(): void {
    var email = this.subscribeForm.controls['email'].value;
    this.isSubscibeLoading = true;
    this.http.post('api/ContactUs/notifyme?email=' + email, null).subscribe(
      (response) => {
        this.isSubscibeLoading = false;
        if (response) {
          this.dialogService.showMessageDialog('Submit completed!');
          this.initSubscribeForm();
        } else {
          this.dialogService.showErrorDialog('There was an error!');
        }
      },
      (err) => {
        this.isSubscibeLoading = false;
        this.dialogService.showErrorDialog('There was an error!');
      }
    );
  }
}
