import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'payperclip-coming-web';

  footerHeader: string = "Let's build a community";
  footerMessage: string = "Start making money from doing what you love.";
  footerButtonActionTitle: string = "Start creating";


  constructor(
    private _primengConfig: PrimeNGConfig,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._primengConfig.ripple = true;
  }

  onRouteChanged(): void{
  }

}
