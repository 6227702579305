<div class="faq-block-root">
  <div class="search-main-container">
    <div class="faq-sec0">
      <div>
        <div class="faq-search-header-block">
          <h1 class="homepage-desktop">
            <span style="display: block;">Ask us anything</span>
          </h1>
          <h1 class="homepage-mobile">
            <span style="display: block;">Ask us anything</span>
          </h1>
        </div>
      </div>
      <div class="search-container">
        <div class="faq-search-input-block">

          <div class="homepage-desktop">
            <form id="searchFormDesktop">
              <div class="p-fluid grid">
                <div class="p-field col-10">
                  <div>
                    <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input name="searchDesktop" id="searchDesktop" class="search-input-field" type="text" pInputText
                        placeholder="Search here" [(ngModel)]="searchValue" (keydown)="onSearchEnterPressed($event)" />
                    </span>
                  </div>
                </div>
                <div class="p-field col-2">
                  <div class="search-button-block">
                    <button type="button" pButton class="p-button-primary" label="Search"
                      (click)="onSearchClicked()"></button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="homepage-mobile">
            <form id="searchFormMobile">
              <div class="p-fluid grid search-form-container">
                <div class="p-field search-input-field-container">
                  <div>
                    <span class="p-input-icon-left">
                      <i class="pi pi-search"></i>
                      <input name="searchMobile" id="searchMobile" class="search-input-field" type="text" pInputText
                        placeholder="Search here" [(ngModel)]="searchValue" (keydown)="onSearchEnterPressed($event)" />
                    </span>
                  </div>
                </div>
                <div class="p-field">
                  <div class="search-button-block">
                    <button type="button" pButton class="p-button-primary" icon="pi pi-search"
                      (click)="onSearchClicked()"></button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
    <div class="faq-search-container-fluid">
      <p-breadcrumb [model]="items" (onItemClick)="breadcrumClick($event)" styleClass="faq-bd"
        [style.visibility]="items.length>1 ? 'visible' : 'hidden'"></p-breadcrumb>
      <div class="search-container">
        <div class="search-result-container">
          <div class="faq-search-results-block">
            <p-dataView [style.display]="show ? 'block' : 'none'" [value]="searchTopicResult" [paginator]="false"
              [rows]="15" layout="grid" [loading]="isLoading" [lazy]="true" (onLazyLoad)="initTopicData($event)"
              [totalRecords]="totalRecords">
              <ng-template let-item pTemplate="gridItem">
                <div class="col-12 lg:col-4 md:col-6 sm:col-6">
                  <div class="faq-search-item-result" (click)="onTopicClicked($event,item)">
                    <div class="faq-search-item-result-header">
                      <p-avatar [image]="item.icon" styleClass="mr-2" size="large"></p-avatar>
                    </div>
                    <div class="faq-sec1-search-item-result-title">
                      {{item.title}}
                    </div>
                    <div class="faq-sec1-search-item-result-description">
                      {{item.description}}
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-dataView>
            <div class="faq-search" [style.display]="show ? 'none' : 'block'">
              <div [style.display]="questionList ? 'block' : 'none'">
              <h1 class="faq-listing-title" [style.display]="faqs.length>0 && searchValue ? 'block' : 'none'">
                {{faqs.length}} results for "{{searchValue}}"</h1>
              <h1 class="faq-listing-title" [style.display]="faqs.length>0 && topicTitle ? 'block' : 'none'">
                {{topicTitle}}</h1>
              <table class="faq-listing-result">
                <tr *ngFor="let faq of faqs">
                  <td>
                    <div (click)="onQuestionClick($event,faq)" class="faq-row">{{faq.title}}</div>
                  </td>
                </tr>
              </table>
            </div>
              <div [style.display]="isDetail ? 'block' : 'none'">
                <h1 class="question-title">{{selectedQuestion.title}}</h1>
                <div [innerHTML]="selectedQuestion.description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="homepage-desktop faq-section2">
    <div class="faq-left">
      <h1 class="faq-section2-header-title">Can't find what you are looking for?</h1>
      <div class="faq-section2-description">
        Can't find the answer you're looking for? Please chat to our friendly team.
      </div>
    </div>
    <div class="faq-section2-subscription">
      <div pButton class="p-button-primary ">
        <span class="home-notify-me-icon-white"></span>
        <span class="header-notify-me" (click)="subscribe()">Submit a request</span>
      </div>
    </div>
  </div>
  <div class="homepage-mobile faq-section2">
    <div class="faq-left-mobile">
      <h1 class="faq-section2-header-title">Can't find what you are looking for?</h1>
      <div class="faq-section2-description">
        Can't find the answer you're looking for? Please chat to our friendly team.
      </div>
    </div>
    <div class="faq-section2-subscription-mobile">
      <div pButton class="p-button-primary ">
        <span class="home-notify-me-icon-white"></span>
        <span class="header-notify-me" (click)="subscribe()">Submit a request</span>
      </div>
    </div>
  </div>
</div>