import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../extra/services/dialog.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  isSubmit: boolean;
  isCaptchaVerified: boolean;
  contactUsForm: FormGroup;
  isLoading: boolean = false;
  appGoogleApiKey = environment.appGoogleApiKey;
  constructor(
    private _location: Location,
    private _http: HttpClient,
    private _dialogService: DialogService
  ) { }
  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.contactUsForm = new FormGroup({
      name: new FormControl('', [
        Validators.maxLength(50)]),
      subject: new FormControl('', [
        Validators.maxLength(150)]),
      email: new FormControl('',[
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      messageTxt: new FormControl('', [
        Validators.required,
        Validators.maxLength(500)])
    });

  }
  resolved(captchaResponse: string) {
    this.isCaptchaVerified = true;
  }
  sendMail(): void
  {
    this.isSubmit = true;
    this.isLoading = true;

    var requestObject = {
      email: this.contactUsForm.controls['email'].value,
      subject: this.contactUsForm.controls['subject'].value,
      message:
        'Email: ' + this.contactUsForm.controls['email'].value +
        '<br>Date: ' + new Date().toLocaleString() +
        '<br>Name: ' + this.contactUsForm.controls['name'].value+
        '<br>Message: ' + this.contactUsForm.controls['messageTxt'].value
    };
    this._http
      .post('api/ContactUs', requestObject).subscribe(
        response => {
          this.isLoading = false;
          this.isCaptchaVerified = false;
          if (response) {
            this.initForm();
            this._dialogService.showMessageDialog("Submit completed!!");                        
          } else {
            this._dialogService.showErrorDialog("There was an error!!");
          }
        },
        err => {
          this.isLoading = false;
          this.isCaptchaVerified = false;
          this._dialogService.showErrorDialog("There was an error!!");
          //alert('There was an error2!');
        }
      );
  }
}
