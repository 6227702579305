<div class="cu-block-root">
  <div class="section0">
    <h1 class="section0-header-title1">Submit a request</h1>

  </div>
  <div class="cu-section1">
    <form [formGroup]="contactUsForm" class="cu-form" enctype="multipart/form-data">
      <div class="card">
        <div class="input-field ui-fluid">
          <h1 class="cu-form-label required">Email</h1>
          <div class="field p-inputgroup">
            <input required class="form-input" type="email" pInputText formControlName="email"
                   placeholder="Enter email address">
            <label class="error-label"
                   *ngIf="!contactUsForm.controls['email'].valid  && (contactUsForm.controls['email'].dirty || contactUsForm.controls['email'].touched)">
              Enter your email address
            </label>
          </div>
        </div>
        <div class="input-field ui-fluid">
          <h1 class="cu-form-label required">Subject</h1>
          <div class="field p-inputgroup">
            <input type="text" class="form-input" pInputText
                   placeholder="Please use a few words to summarize your question" formControlName="subject">
            <label class="error-label"
                   *ngIf="!contactUsForm.controls['subject'].valid  && (contactUsForm.controls['subject'].dirty || contactUsForm.controls['subject'].touched)">
              Please enter a valid message.
            </label>
          </div>
        </div>
        <div class="input-field ui-fluid">
          <h1 class="cu-form-label required">Description</h1>
          <div class="field p-inputgroup">
            <textarea required type="text" class="form-input" pInputText rows="5" cols="70"
                      placeholder="Lease enter the details of your request. A member of our community happiness team will reach out with a response"
                      id="textArea" formControlName="messageTxt"></textarea>
            <label class="error-label"
                   *ngIf="!contactUsForm.controls['messageTxt'].valid  && (contactUsForm.controls['messageTxt'].dirty || contactUsForm.controls['messageTxt'].touched)">
              Please enter a valid message.
            </label>
          </div>
        </div>
        <div class="col-12">
          <re-captcha (resolved)="resolved($event)" class="center"
                      siteKey="{{appGoogleApiKey}}">
          </re-captcha>
        </div>
        <div class="cu-form-submit center input-field ui-fluid">
          <button type="button" pButton [loading]="isLoading" [disabled]="!isCaptchaVerified || !contactUsForm.controls['email'].valid || !contactUsForm.controls['messageTxt'].valid || !contactUsForm.controls['subject'].valid" class="p-button-primary  cu-submit-button submit p-button p-component"
                  (click)="sendMail()">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>

</div>
