import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../../extra/services/dialog.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  subscribeForm: FormGroup;
  isSubscibeLoading: boolean = false;
  isLoading: boolean = false;
  sliderDesktopList = [
    { imageSrc: '../../../assets/Images/About/slider-1.svg'}
  ];
searchResult:any = [
  { imgSrc:'../../../assets/Images/About/AboutUs-Section3-Posts.svg', title:"Post",description:"Share your creative contents with 8 clip type options which they can select the visibility of that clip and also a free preview as a thumbnail or a trailer."},
  { imgSrc:'../../../assets/Images/About/AboutUs-Section3-Messages.svg', title:"Messages",description:"Receive messsages of encouragement from fans or have a video chat with them."},
  { imgSrc:'../../../assets/Images/About/AboutUs-Section3-Myfans.svg', title:"My fans",description:"View all their fans. Artists can see a ranking of their fans to know who has paid them the most, favourite a specific fan."},
  { imgSrc:'../../../assets/Images/About/AboutUs-Section3-Mywallet.svg', title:"My wallet",description:"Users can view their balance and how many currencies they have. Users can select a currency and see an in depth detail of it or withdraw to their personal bank account."},
  { imgSrc:'../../../assets/Images/About/AboutUs-Section3-Analytics.svg', title:"Analytics",description:"Allows the user to view their performance as an Artist and also their earnings. Users can also view individual clip performances or an in depth detail of their fans."},
  { imgSrc:'../../../assets/Images/About/AboutUs-Section3-Settings.svg', title:"Settings",description:"Easy to edit their account or notification settings. And also manage their privacy and safety their account. API implementation is a future implementation."},
]
  constructor(
    private http: HttpClient,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.InitSubscribeForm();
    window.scrollTo({
      top: 0
    });
  }

  InitSubscribeForm(): void {
    this.subscribeForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])
    });
  }

  subscribe(): void{
    var email = this.subscribeForm.controls['email'].value;
    this.isSubscibeLoading = true;
    this.http
      .post('api/ContactUs/notifyme?email=' + email, null).subscribe(
        response => {
          this.isSubscibeLoading = false;
          if (response) {
            this.dialogService.showMessageDialog('Submit completed!');
            this.InitSubscribeForm();
          } else {
            this.dialogService.showErrorDialog('There was an error!');
          }
        },
        err => {
          this.isSubscibeLoading = false;
          this.dialogService.showErrorDialog('There was an error!');
        }
      );

  }
}
