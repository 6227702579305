import { Component, OnInit } from '@angular/core';
import { SearchResult } from 'src/app/extra/models/search-result.model';
import FaqJson from '../../extra/data/faqs.json'
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';
import { ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FAQSComponent implements OnInit {
  items: MenuItem[];
  searchTopicResult: Array<SearchResult> = [];
  searchValue: string = '';
  show: boolean = true;
  totalRecords: number;
  isLoading: boolean = false;
  faqs: any = [];
  topicTitle: string;
  isDetail: boolean = false;
  questionList: boolean = false;
  selectedQuestion: any = { title: "", description: "" };
  constructor(private router:Router
  ) { }

  ngOnInit(): void {
    this.items = [
    ];
    window.scrollTo({
      top: 0
    });
  }

  initTopicData($event = null): void {
    FaqJson.forEach(element => {
      this.searchTopicResult.push({
        title: element.title,
        description: element.description,
        icon: element.icon,
        id: element.id
      });
    });
  }

  onSearchEnterPressed($event: KeyboardEvent) {
    if ($event.key == 'Enter') {
      this.onSearchClicked();
    }
  }

  public onQuestionClick(event, item: any) {
    this.isDetail = true;
    this.questionList = false;    
    this.selectedQuestion = item;
    this.show = false;
    var temp = [];

    for (var i = 0; i < this.items.length; i++) {
      temp.push(this.items[i]);
    }
    temp.push({ label: item.title, id: "2" });
    this.items = temp;
  }

  public breadcrumClick(event) {    
    var y: number = event.item.id;
    for (let index = this.items.length - 1; index > y; index--) {
      const element = this.items[index];
      this.items.pop();
      if (element.id == "1") {
        this.show = true;
        this.isDetail = false;
      }
      if(element.id=="2"){
        this.show=false;
        this.questionList = true;
        this.isDetail = false;
      }
    }
  }
  public onTopicClicked(event, item: any) {
    var id = item.id;
    var topic = FaqJson.find(x => x.id == id);
    if (topic) {
      this.questionList = true;
      this.faqs = [];
      var questions = topic.questions;
      if (questions && questions.length > 0) {
        questions.forEach(question => {
          if (question) {
            var temp = { id: topic.id, title: question.title, description: question.description };
            this.faqs.push(temp);
          }
        });
      }
      this.show = false;
      this.topicTitle = topic.title;
      this.items = [{ label: 'FAQs', id: "0" },
      { label: this.topicTitle, id: "1" }];      
    }
  }

  onSearchClicked() {
    this.items = [{ label: 'FAQs', id: "0" },
    { label: "Search Result", id: "1" }];
    if (this.searchValue) {
      this.show = false;
    }
    else {
      this.show = true;
    }
    let query: string = this.searchValue.toLowerCase() ?? "";
    this.faqs = [];
    FaqJson.forEach(element => {
      var questions = element.questions.filter(y => y.title.toLowerCase().indexOf(query) !== -1 || y.description.indexOf(query) !== -1);
      if (questions && questions.length > 0) {
        questions.forEach(question => {
          if (question) {
            var temp = { id: element.id, title: question.title, description: question.description };
            this.faqs.push(temp);
          }
        });
      }
    });
  }
  subscribe(): void {
    this.router.navigateByUrl('faqs/submit-request');
  }
}