<app-header></app-header>
<div class="app-container">
  <router-outlet (activate)="onRouteChanged()"></router-outlet>
</div>
<app-footer [footerHeader]="footerHeader"
            [footerMessage]="footerMessage"
            [footerButtonActionTitle]="footerButtonActionTitle">
</app-footer>
<!-- Confirm dialog -->
<p-confirmDialog [style]="{width: '30vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"
                 key="confirmDialog" icon="pi pi-question-circle"></p-confirmDialog>

<!-- Error dialog -->
<p-confirmDialog #errcd header="Error" icon="pi pi-exclamation-circle"
                 [style]="{width: '30vw'}" [baseZIndex]="10000" key="errorDialog">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="OK" (click)="errcd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<!-- Message dialog -->
<p-confirmDialog #msgcd header="Information" icon="pi pi-info-circle"
                 [style]="{width: '30vw'}" [baseZIndex]="10000" key="messageDialog">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" label="OK" (click)="msgcd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<p-toast position="top-right" key="notifyToast"></p-toast>
